<template>
  <div class="v-disconts-page" id="disconts">
    <div class="discont">
      <div class="rules-position">
        <legend class="rules-zag">Условия действий скидок</legend>
        <div class="rules">1. В понедельник, вторник, среду и четверг с 09.00 ч. до 18.00 ч. номер:</div>
        <div class="rules" style="text-indent: 3em;">«Уют» – 1000 руб./час </div>
        <div class="rules" style="text-indent: 3em;">«Комфорт» - 1200 руб./час </div>
        <div class="rules" style="text-indent: 3em;">«Эрчим» - 1300 руб./час</div>
        <div class="rules" style="text-indent: 3em;">«Семейный» - 1500 руб./час </div>
        <div class="rules" style="text-indent: 3em;">«Берлога», «Русский дом» и «VIP» - 1 700 руб./час. </div>
        <div class="rules">2. В понедельник, вторник, среду и четверг во время действия скидок, сертификаты стоимостью 500 руб. для оплаты не принимаются.</div>
        <div class="rules">3.	При разовом заказе и единовременной оплате номера на 4 часа, 1 час предоставляется в подарок.</div>
        <div class="rules">4.	В понедельник, вторник, среду и четверг, во время действия скидок, акция «1 час в подарок» при заказе номера на 4 часа не предоставляется.</div>
        <div class="rules">5.	Скидка 10% предоставляется на посещение номера в день рождения (+/- 3 дня), за исключением во время действия скидок в понедельник, вторник, среду и четверг.</div>
        <div class="rules">6.	В праздничные дни, скидок и акций нет, за исключением скидки 10% в честь дня рождения.</div>
        <div class="rules">7.	При использовании подарочного сертификата стоимостью 500 рублей, скидки на него не распространяются.</div>
        <div class="rules">8.	Делить подарочный сертификат на несколько посещений нельзя, он используется при одном посещении.</div>
        <div class="rules">9. В период отключения ГВС все скидки и акции отменяются, за исключением скидки 10% в честь дня рождения и акции «1 час в подарок».</div>
      </div>
    </div>
    <img src="@/images/Disconts.jpg" class="card-gallery"/>
  </div>
</template>

<script>

export default {
  name: 'disconts-page',
  components: {
  }
}
</script>

<style>
.v-disconts-page{
  border: 5px solid #121820;
}

.discont{
  padding: 20px;
}

</style>
